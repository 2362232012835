import { message } from 'antd';
import Axios from 'axios';
import { batch } from 'react-redux';

import NetworkStatus from '../../utils/enums/NetworkStatus';
import { CheckoutFields } from '../reducers/order';
import { TAction } from '../store';
import { CartActions } from './cart';
import { OrderActions } from './order';

export const Types = {
    SET_INTERVALS: 'ORDER@SET:INTERVALS',
};

export type TSetIntervals = {
    type: typeof Types.SET_INTERVALS;
    payload: TPeriod[];
};

export type TIntervalActions = TSetIntervals;

type IntervalActionsType = {
    fetchIntervals: (addressUuid: string) => TAction<Promise<void>>;
    assignInterval: (intervalId: string, date: string) => TAction<Promise<void>>;
    setIntervals: (intervals: TPeriod[]) => TSetIntervals;
};

export type TInterval = {
    day_of_week: number;
    default: number;
    district_id: number;
    express_interval: string;
    id: number;
    is_express: number;
    label: string;
    minutes_for_disable: number;
    price: string;
    shift_closing_to_end: number;
    time_end: string;
    time_start: string;
};

export type TPeriod = {
    date: string;
    label: string;
    intervals: TInterval[];
};

type TAssign = {
    cart: string;
    date: string;
    periodId: string;
};

const errorHandler = (error: any, defaultMessage: string) => {
    if (Axios.isCancel(error)) {
        return;
    }
    const errorMessage = error?.message || defaultMessage;
    message.error(errorMessage);
};

export const IntervalActions: IntervalActionsType = {
    fetchIntervals(addressUuid) {
        return async (dispatch, _, { api, defaultFetch }) => {
            dispatch(OrderActions.setNetworkStatus('intervals', NetworkStatus.loading));
            await defaultFetch
                .get<{ data: TPeriod[]; success: boolean }>(api.getIntervals, {
                    params: {
                        addressUuid: addressUuid,
                    },
                })
                .then(({ data }) => {
                    dispatch(this.setIntervals(data.data));
                    dispatch(OrderActions.setNetworkStatus('intervals', NetworkStatus.ready));
                })
                .catch(e => errorHandler(e, 'Failed to fetch intervals'));
        };
    },
    assignInterval(intervalId, date) {
        return async (dispatch, getState, { defaultFetch, services }) => {
            const { info } = getState().cart;
            const cart = info?.cart;
            if (cart) {
                const url = `${services.cart.domain}${services.cart.assignInterval}`;
                const data: TAssign = {
                    cart,
                    date,
                    periodId: intervalId,
                };
                await defaultFetch
                    .post(url, data)
                    .then(() => {
                        batch(() => {
                            dispatch(CartActions.calculate());
                            dispatch(OrderActions.setReadyField(CheckoutFields.interval, true));
                        });
                    })
                    .catch(e => {
                        dispatch(OrderActions.setReadyField(CheckoutFields.interval, false));
                        errorHandler(e, 'Failed to assign interval');
                    });
            }

            return;
        };
    },
    setIntervals(intervals) {
        return {
            type: Types.SET_INTERVALS,
            payload: intervals,
        };
    },
};
