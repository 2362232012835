export default {
    title: 'Login / Registration',
    content: {
        phoneNumber: {
            article: 'Login or register to place orders, receive bonuses and more',
            termsOfUse: {
                text: 'By entering your phone number at the entrance, you accept',
                link: 'terms of use',
            },
        },
        smsCode: {
            text: 'Enter the SMS code received on the specified number',
            codeDidNotCome: 'Code did not come? Send again after {{value}} sec',
        },
        email: {
            text: 'Enter your email address',
        },
    },
    form: {
        fields: {
            username: {
                placeholder: 'example@mail.com',
                label: 'E-mail',
                validations: {
                    require: 'this field is required',
                    email: 'You specified an invalid email address',
                },
            },
            password: {
                label: 'Password',
                validations: {
                    require: 'this field is required',
                },
            },
            rememberMe: {
                label: 'Remember me',
            },
            phoneNumber: {
                label: 'Phone',
                validations: {
                    phone: 'You specified an invalid phone number',
                    require: 'This field is required',
                },
            },
            email: {
                label: 'email',
            },
        },
    },
    links: {
        registration: 'Registration',
        recoveryPassword: 'I forgot my password :(',
    },
    profileButton: 'My profile',
    loginButton: 'Login / Registration',
    logoutButton: 'Logout',
    submitButton: {
        next: 'Next',
        confirm: 'Confirm',
    },
    error: {
        phoneNumber: 'Phone error',
        smsCode: 'Code error',
        email: 'Email error',
    },
};
