import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import sha1 from 'sha1';

import { PartnerActions } from '../redux/actions/partner';
import { UserActions } from '../redux/actions/user';
import store from '../redux/store';
import { getAppConfig } from './appConfig';

export const saveTokens = (tokens: { token: string; refresh: string }) => {
    localStorage.setItem('token', tokens.token);
    localStorage.setItem('refreshToken', tokens.refresh);
};

export const cleanTokens = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
};

const { url, defaultToken, api, services } = getAppConfig();

const cancelToken = (config: AxiosRequestConfig) => {
    if (config.cancelToken !== undefined) {
        const cancelToken = axios.CancelToken.source();
        config.cancelToken = cancelToken.token;
        const hash = sha1(config.url as any);
        //@ts-ignore
        config.hash = hash;

        if (requests[hash]) {
            requests[hash].cancelToken.cancel(config.url);
        }
        requests[hash] = {
            cancelToken,
        };
    }
};

const requests: Record<string, { cancelToken: CancelTokenSource }> = {};

type TRefreshResponse = {
    token: string;
    refresh_token: string;
};

export const refreshToken = () => {
    const rToken = getRefreshToken();
    if (!rToken) {
        throw Error('Access error');
    }

    const url = `${services.auth.domain}${services.auth.refreshTokenUrl}`;

    return axios
        .post<TRefreshResponse>(url, {
            refresh_token: rToken,
        })
        .then(({ data }) => {
            saveTokens({
                token: data.token,
                refresh: data.refresh_token,
            });
        });
};

const responseError = (error: any) => {
    const originRequest = error.config;

    if (axios.isCancel(error)) {
        return Promise.reject(error);
    }

    if (error.response && error.response.status === 401) {
        return refreshToken().then(() => {
            return PartnerActions.partnerEnrichToken()(store.dispatch, store.getState, {
                services,
                api,
                defaultFetch,
                orderFetch,
            })
                .then(() => {
                    return UserActions.fetchUser()(store.dispatch, store.getState, {
                        services,
                        api,
                        defaultFetch,
                        orderFetch,
                    });
                })
                .then(() => {
                    originRequest.headers.Authorization = getToken();

                    return defaultFetch.request(originRequest);
                });
        });
    } else {
        throw error;
    }
};

const getToken = () => {
    let token = null;
    const localToken = localStorage.getItem('token');

    if (defaultToken) {
        token = defaultToken;
    }

    if (localToken) {
        token = localToken;
    }

    return token ? `Bearer ${token}` : undefined;
};

const getRefreshToken = () => {
    return localStorage.getItem('refreshToken');
};

const source = 'site';
const lang = 'ru';

export const defaultFetch = axios.create({
    baseURL: url,
    withCredentials: false,
    headers: {
        'Content-Type': 'application/json',
    },
});

defaultFetch.interceptors.request.use(
    config => {
        cancelToken(config);

        config.headers = {
            Authorization: getToken(),
            ...config.headers,
        };

        return config;
    },
    error => {
        return Promise.reject(error);
    },
);

defaultFetch.interceptors.response.use(
    config => {
        //@ts-ignore
        const hash = config.config.hash;
        if (requests[hash]) {
            delete requests[hash];
        }

        return config;
    },
    error => responseError(error),
);

export const orderFetch = axios.create({
    baseURL: url,
    withCredentials: false,
    headers: {
        'Content-Type': 'application/json',
        'X-Source': source,
        'Accept-Language': lang,
    },
});

orderFetch.interceptors.request.use(
    config => {
        config.headers = {
            ...config.headers,
            Authorization: getToken(),
        };

        return config;
    },
    error => {
        return Promise.reject(error);
    },
);

orderFetch.interceptors.response.use(
    config => {
        return config;
    },
    error => responseError(error),
);
