import catalogBottomSection from './catalogBottomSection';
import layout from './layout';
import login from './login';
import ordersHistory from './ordersHistory';
import pointsModal from './pointsModal';
import product from './product';
import profile from './profile';
import staticPages from './staticPages';

export default {
    layout,
    staticPages,
    login,
    product,
    profile,
    ordersHistory,
    pointsModal,
    catalogBottomSection,
};
