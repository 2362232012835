export default {
    title: 'Вход / Регистрация',
    content: {
        phoneNumber: {
            article:
                'Войдите или зарегистрируйтесь, чтобы делать заказы, получать бонусы и многое другое',
            termsOfUse: {
                text: 'Указывая при входе свой номер телефона, вы принимаете',
                link: 'пользовательское соглашение',
            },
        },
        smsCode: {
            text: 'Введите SMS-код, полученный на указанный номер',
            codeDidNotCome: 'Код не пришел? Отправить повторно через {{value}} сек',
        },
        email: {
            text: 'Введите email',
        },
    },
    form: {
        fields: {
            username: {
                placeholder: 'example@mail.com',
                label: 'E-mail',
                validations: {
                    require: 'Поле обязательно для заполнения',
                    email: 'Вы указали невалидный E-mail',
                },
            },
            password: {
                label: 'Пароль',
                validations: {
                    require: 'Поле обязательно для заполнения',
                },
            },
            rememberMe: {
                label: 'Запомнить меня',
            },
            phoneNumber: {
                label: 'Телефон',
                validations: {
                    phone: 'Вы указали невалидный номер телефона',
                    require: 'Поле обязательно для заполнения',
                },
            },
            email: {
                label: 'email',
            },
        },
    },
    links: {
        registration: 'Регистрация',
        recoveryPassword: 'Я забыл пароль :(',
    },
    profileButton: 'Кабинет',
    loginButton: 'Вход / Регистрация',
    logoutButton: 'Выход',
    submitButton: {
        next: 'Далее',
        confirm: 'Подтвердить',
    },
    error: {
        phoneNumber: 'Ошибка номера телефона',
        smsCode: 'Ошибка смс кода',
        email: 'Ошибка email',
    },
};
