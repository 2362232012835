import JwtDecode from 'jwt-decode';

import { TokenPayload, UserRoles } from '../types';

export const decodeToken = (token: string): TokenPayload => {
    return JwtDecode<TokenPayload>(token);
};

export const isAnonToken = (roles: UserRoles[]) => {
    const anonTypes = [UserRoles.AnonymousAggregatorClient, UserRoles.RoleClientApp];

    return anonTypes.some(type => roles.includes(type));
};
