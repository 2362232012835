export default {
    title: 'Кабинет',
    myAddress: 'Мои адреса',
    historyOrders: 'История заказов',
    messages: 'Чат с поддержкой',
    bonuses: 'Ваши баллы',
    share: 'Поделиться ссылкой',
    delivery: 'Доставка и оплата',
    aboutCompany: 'О компании',
    downloadApp: 'Скачать приложение',
    signOut: 'Выйти из профиля',
    userPlaceholder: 'Имя пользователя',
    emailPlaceholder: 'email',
};
