import { message } from 'antd';
import Axios from 'axios';
import { batch } from 'react-redux';

import NetworkStatus from '../../utils/enums/NetworkStatus';
import URLHelper from '../../utils/URLHelper';
import { CheckoutFields } from '../reducers/order';
import { TAction } from '../store';
import { TAddressAssign } from './addresses';
import { TSetNetworkStatus } from './history';
import { OrderActions } from './order';
import { ReturnedContainers } from './returnedContainers';

export const Types = {
    INIT_CART: 'CART@INIT:CART',
    SET_PRODUCTS: 'CART@SET:PRODUCTS',
    SET_CALCULATE: 'CART@SET:CALCULATE',
    SET_CALCULATE_NETWORK_STATUS: 'CART@SET:CALCULATE:NETWORK:STATUS',
    SET_DETAIL_NETWORK_STATUS: 'CART@SET:DETAIL:NETWORK:STATUS',
};

export type TInitCart = {
    '@context': string;
    '@id': string;
    '@type': string;
    partner?: string;
    cart: string;
    id?: string;
    createdAt?: string;
    cartId?: string;
};

export type TDetailsCart = {
    '@context': string;
    '@id': string;
    '@type': string;
    address: TAddressAssign & {
        '@id': string;
        '@type': string;
    };
    deliveryPeriod: null;
    payment: null;
    user: null;
    returnableContainer: {
        '@id': string;
        '@type': string;
        quantity: number;
    };
    note: null;
    callRestriction: null;
    loyaltyPoints: null;
    promotionCode: null;
    items: {
        '@id': string;
        '@type': string;
        product: {
            '@id': string;
            '@type': string;
            externalId: number;
            name: string;
        };
        productSet: null;
        quantity: number;
        fromRecommended: boolean;
        type: string;
    }[];
};

export type TCartCalculate = {
    '@context': string;
    '@id': string;
    '@type': string;
    items: {
        '@type': string;
        '@id': string;
        id: string;
        price: {
            amount: string;
            currency: string;
        };
        quantity: number;
        sum: {
            amount: string;
            currency: string;
        };
        cashback: {
            amount: string;
            currency: string;
        };
        cashbackSum: {
            amount: string;
            currency: string;
        };
        type: string;
        product: {
            '@id': string;
            '@type': string;
            externalId: number;
            name: string;
        };
    }[];
    cartSum: {
        amount: string;
        currency: string;
    };
    deliveryCost: {
        amount: string;
        currency: string;
    };
    sum: {
        amount: string;
        currency: string;
    };
    maxCashbackPoints: {
        amount: string;
        currency: string;
    };
    cashbackSum: {
        amount: string;
        currency: string;
    };
    total: {
        amount: string;
        currency: string;
    };
};

export type AuthenticateType = {
    '@context': string;
    '@id': string;
    '@type': string;
    authenticatedCart: string;
    cart: string;
    id: string;
    createdAt: string;
};

export type TCartItem = {
    productId: number;
    quantity: number;
    cartItem?: string;
    type: string;
    '@context'?: string;
    '@id'?: string;
    '@type'?: string;
    cart?: string;
    createdAt?: string;
    id?: string;
};

export type TInitCartAction = {
    type: typeof Types.INIT_CART;
    payload: TInitCart;
};

export type TSetCartItemsAction = {
    type: typeof Types.SET_PRODUCTS;
    payload: Record<number, TCartItem> | null;
};

export type TSetCalculate = {
    type: typeof Types.SET_CALCULATE;
    payload: TCartCalculate | null;
};

export type TSetCalculateNetworkStatus = {
    type: typeof Types.SET_CALCULATE_NETWORK_STATUS;
    payload: NetworkStatus;
};

export type TSetDetailNetworkStatus = {
    type: typeof Types.SET_DETAIL_NETWORK_STATUS;
    payload: NetworkStatus;
};

export type TCartActions =
    | TInitCartAction
    | TSetCartItemsAction
    | TSetCalculate
    | TSetDetailNetworkStatus
    | TSetCalculateNetworkStatus;

type CartActionsType = {
    restoreCart: () => TAction<Promise<void>>;
    restoreProducts: (
        products: { productId: number; quantity: number }[],
    ) => TAction<Promise<void>>;
    initCart: () => TAction<Promise<void>>;
    addProductToCart: (
        quantity: number,
        productId: number,
        productName: string,
        price: number,
        type: string,
    ) => TAction<Promise<void>>;
    reduceProduct: (quantity: number, productId: number) => TAction<Promise<void>>;
    removeProduct: (productId: number) => TAction<Promise<void>>;
    calculate: () => TAction<Promise<void>>;
    clearCart: () => TAction<Promise<void>>;
    authenticate: () => TAction<Promise<void>>;
    setInfoCart: (payload: TInitCart) => TInitCartAction;
    setCartItems: (payload: Record<number, TCartItem> | null) => TSetCartItemsAction;
    setCalculate: (payload: TCartCalculate | null) => TSetCartItemsAction;
    setCalculateNetworkStatus: (status: NetworkStatus) => TSetCalculateNetworkStatus;
    setDetailNetworkStatus: (status: NetworkStatus) => TSetNetworkStatus;
};

const errorHandler = (error: any, defaultMessage: string) => {
    if (Axios.isCancel(error)) {
        return;
    }
    const errorMessage = error?.message || defaultMessage;
    message.error(errorMessage);
};

export const CartActions: CartActionsType = {
    initCart() {
        return async (dispatch, _, { defaultFetch, services }) => {
            const url = `${services.cart.domain}${services.cart.initCart}`;
            await defaultFetch
                .post<TInitCart>(url, {})
                .then(({ data }) => {
                    localStorage.setItem('cartId', data.cart);
                    dispatch(this.setInfoCart(data));
                })
                .catch(e => errorHandler(e, 'Failed to init cart'));
        };
    },
    restoreCart() {
        return async (dispatch, _, { orderFetch, services }) => {
            const cartId = localStorage.getItem('cartId');
            if (cartId) {
                const url = `${services.cart.domain}${services.cart.restore}`;
                dispatch(this.setDetailNetworkStatus(NetworkStatus.loading));
                orderFetch
                    .get<TDetailsCart>(
                        URLHelper.buildUrl(url, {
                            cartId,
                        }),
                    )
                    .then(({ data }) => {
                        const products = data.items.map(item => ({
                            productId: item.product.externalId,
                            quantity: item.quantity,
                            cartItem: item['@id'],
                        }));
                        batch(() => {
                            dispatch(
                                this.setInfoCart({
                                    '@context': data['@context'],
                                    '@id': data['@id'],
                                    '@type': data['@type'],
                                    cart: cartId,
                                }),
                            );
                            dispatch(this.restoreProducts(products));
                            dispatch(this.calculate());
                            dispatch(
                                ReturnedContainers.setQuantity(data.returnableContainer?.quantity),
                            );
                            dispatch(
                                OrderActions.setDefaultField(CheckoutFields.address, data.address),
                            );
                            dispatch(
                                OrderActions.setDefaultField(CheckoutFields.payment, data.payment),
                            );
                            dispatch(OrderActions.setDefaultField(CheckoutFields.note, data.note));
                        });
                    })
                    .catch(e => {
                        errorHandler(e, 'Failed to restore cart');
                        localStorage.removeItem('cartId');
                    });
            }
        };
    },
    restoreProducts(products) {
        return async (dispatch, getState) => {
            const { catalog } = getState().catalog;
            const items = catalog.map(item => item.products).flat();
            const cartItems: Record<number, TCartItem> = {};
            let maxQuantityContainers = 0;
            products.forEach(product => {
                const item = items.find(item => item.id === product.productId);
                if (item) {
                    const cartItem = {
                        ...product,
                        type: item.type,
                    };
                    cartItems[product.productId] = cartItem;
                    if (cartItem.type === 'Вода') {
                        maxQuantityContainers += cartItem.quantity;
                    }
                }
            });
            if (Object.keys(cartItems).length > 0) {
                batch(() => {
                    dispatch(this.setCartItems(cartItems));
                    dispatch(ReturnedContainers.setMaxQuantity(maxQuantityContainers));
                });
            }
            dispatch(this.setDetailNetworkStatus(NetworkStatus.ready));
        };
    },
    authenticate() {
        return async (dispatch, getState, { orderFetch, services }) => {
            const { info } = getState().cart;
            const { selectedAddress } = getState().addresses;
            if (info) {
                const data = {
                    cart: info.cart,
                    addressId: selectedAddress.external_id,
                };
                const url = `${services.cart.domain}${services.cart.authenticate}`;
                await orderFetch
                    .post<AuthenticateType>(url, data)
                    .then(({ data }) => {
                        info.cart = data.authenticatedCart;
                        dispatch(this.setInfoCart(info));
                    })
                    .catch(e => errorHandler(e, 'Failed to authenticate cart'));
            }
        };
    },
    addProductToCart(quantity, productId, productName, price, type) {
        return async (dispatch, getState, { defaultFetch, services }) => {
            const { info } = getState().cart;
            const cart = info?.cart;
            if (cart) {
                const data = {
                    quantity,
                    productId,
                    productName,
                    cart,
                    price,
                };
                const url = `${services.cart.domain}${services.cart.addProduct}`;
                await defaultFetch
                    .post<TCartItem>(url, data)
                    .then(({ data }) => {
                        const { items } = getState().cart;
                        batch(() => {
                            const product = {
                                productId: data.productId,
                                quantity: data.quantity,
                                cartItem: data.cartItem,
                                type: type,
                            };
                            if (items) {
                                items[data.productId] = product;
                                dispatch(this.setCartItems({ ...items }));
                            } else {
                                dispatch(
                                    this.setCartItems({
                                        [data.productId]: product,
                                    }),
                                );
                            }
                            if (type === 'Вода') {
                                dispatch(ReturnedContainers.addReturnedContainer(quantity));
                            } else {
                                dispatch(this.calculate());
                            }
                        });
                    })
                    .catch(e => errorHandler(e, 'Failed to add product to cart'));
            }
        };
    },
    reduceProduct(quantity, productId) {
        return async (dispatch, getState, { defaultFetch, services }) => {
            const { info } = getState().cart;
            const cart = info?.cart;
            if (cart) {
                const data = {
                    quantity,
                    productId,
                    cart,
                };
                const url = `${services.cart.domain}${services.cart.reduceProduct}`;
                defaultFetch
                    .post<TCartItem>(url, data)
                    .then(({ data }) => {
                        const { items } = getState().cart;
                        if (items) {
                            items[data.productId].quantity = data.quantity;
                            batch(() => {
                                dispatch(this.setCartItems({ ...items }));
                                dispatch(ReturnedContainers.reduceReturnedContainer(quantity));
                                dispatch(this.calculate());
                            });
                        }
                    })
                    .catch(e => errorHandler(e, 'Failed to reduce product'));
            }
        };
    },
    removeProduct(productId) {
        return async (dispatch, getState, { defaultFetch, services }) => {
            const { info, items } = getState().cart;
            const cart = info?.cart;
            if (cart && items) {
                const product = items[productId];
                const url = `${services.cart.domain}${services.cart.removeProduct}`;
                const data = {
                    cart: cart,
                    cartItem: product.cartItem,
                };
                defaultFetch
                    .post(url, data)
                    .then(() => {
                        delete items[productId];
                        const isNotEmptyCart = Object.keys(items).length > 0;
                        batch(() => {
                            dispatch(this.setCartItems(isNotEmptyCart ? { ...items } : null));
                            if (product.type === 'Вода') {
                                dispatch(
                                    ReturnedContainers.reduceReturnedContainer(product.quantity),
                                );
                            }

                            if (isNotEmptyCart) {
                                dispatch(this.calculate());
                            }
                        });
                    })
                    .catch(e => errorHandler(e, 'Failed to remove product'));
            }
        };
    },
    calculate() {
        return async (dispatch, getState, { orderFetch, services }) => {
            const { info } = getState().cart;
            const cart = info?.cart;
            if (cart) {
                const url = `${services.cart.domain}${services.cart.calculate}`;
                const data = {
                    cart,
                };
                dispatch(this.setCalculateNetworkStatus(NetworkStatus.loading));
                orderFetch
                    .post<TCartCalculate>(url, data)
                    .then(({ data }) => {
                        batch(() => {
                            dispatch(this.setCalculateNetworkStatus(NetworkStatus.ready));
                            dispatch(this.setCalculate(data));
                        });
                    })
                    .catch(e => errorHandler(e, 'Failed to calculate'));
            }
        };
    },
    clearCart() {
        return async dispatch => {
            batch(() => {
                localStorage.removeItem('cartId');
                dispatch(this.setCalculate(null));
                dispatch(this.setCartItems(null));
            });
        };
    },
    setCartItems(payload) {
        return {
            type: Types.SET_PRODUCTS,
            payload,
        };
    },
    setCalculate(payload) {
        return {
            type: Types.SET_CALCULATE,
            payload,
        };
    },
    setInfoCart(payload) {
        return {
            type: Types.INIT_CART,
            payload,
        };
    },
    setCalculateNetworkStatus(status) {
        return {
            type: Types.SET_CALCULATE_NETWORK_STATUS,
            payload: status,
        };
    },
    setDetailNetworkStatus(status) {
        return {
            type: Types.SET_DETAIL_NETWORK_STATUS,
            payload: status,
        };
    },
};
