export default {
    title: 'Bonus points',
    login: 'Login or register to earn points',
    marketing:
        '1 point = 1 ruble How to get bonuses? Next to the price of each item is the number of points you will receive when you purchase that item. Bonuses are awarded after the successful delivery of the order (for canceled orders and for orders fully or partially paid with points, bonuses are not awarded). How to spend bonuses? Pay for purchases with points when placing an order through the application.',
    download: {
        start: 'Download the app',
        end: 'to use bonuses for payment',
    },
};
