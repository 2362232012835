export default {
    title: 'My profile',
    myAddress: 'My addresses',
    historyOrders: 'Order history',
    messages: 'Support Chat',
    bonuses: 'Your points',
    share: 'Share link',
    delivery: 'Shipping and payment',
    aboutCompany: 'About company',
    downloadApp: 'Download app',
    signOut: 'Sign out',
    userPlaceholder: 'User name',
    emailPlaceholder: 'email',
};
