export default {
    aboutCompany: {
        title: 'About company',
    },
    deliveryPayment: {
        title: 'Delivery & payment',
        shippingTime: 'Delivery hours:',
        titlePaymentMethods: 'Payment methods:',
        paymentMethods: {
            offlineAcquiring: 'Bank card',
            cash: 'Cash',
            googlePay: 'Google Pay',
            applePay: 'Apple Pay ',
            sberbank: 'Sberbank',
            loyalty: 'Pay with points',
            contract: 'Under contract',
            eximbank: 'Eximbank',
            sberbankOnlineByPhone: 'SberPay',
            liqpay: 'Liqpay',
            tinkoff: 'Tinkoff',
        },
    },
    downloadAppModal: {
        title: 'Download app',
        text: 'Download the app and place your orders conveniently!',
        textMobile: 'Download the app and place orders conveniently',
    },
};
