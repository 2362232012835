import Axios from 'axios';

import NetworkStatus from '../../utils/enums/NetworkStatus';
import { PaymentCodes } from '../../utils/enums/PaymentCodes';
import { CheckoutFields } from '../reducers/order';
import { TAction } from '../store';
import { OrderActions } from './order';

export const Types = {
    SET_PAYMENTS: 'ORDER@SET:PAYMENTS',
};

export type TSetPayments = {
    type: typeof Types.SET_PAYMENTS;
    payload: TPayment[];
};

export type TPaymentActions = TSetPayments;

type PaymentActionsType = {
    fetchPayments: () => TAction<Promise<void>>;
    assignPayment: (payment: TPayment) => TAction<Promise<void>>;
    setPayments: (payment: TPayment[]) => TSetPayments;
};

export type TPayment = {
    '@id': string;
    '@type': string;
    gatewayName: string;
    enabled: boolean;
    notifyUrl: string | null;
    comment: string | null;
    position: number;
    description: string;
    setup: {
        '@id': string;
        '@type': string;
        name: string;
        code: PaymentCodes;
        description: string;
        legacy: boolean;
    };
};

const paymentCancelToken = Axios.CancelToken.source();

export const PaymentActions: PaymentActionsType = {
    fetchPayments() {
        return async (dispatch, _, { services, defaultFetch }) => {
            const url = `${services.payment.domain}${services.payment.getPaymentMethods}`;
            dispatch(OrderActions.setNetworkStatus('payments', NetworkStatus.loading));
            defaultFetch
                .get(url, {
                    cancelToken: paymentCancelToken.token,
                })
                .then(({ data }) => {
                    dispatch(OrderActions.setNetworkStatus('payments', NetworkStatus.ready));
                    dispatch(this.setPayments(data['hydra:member']));
                })
                .catch(error => {
                    dispatch(OrderActions.setNetworkStatus('payments', NetworkStatus.ready));

                    if (Axios.isCancel(error)) {
                        return;
                    }
                    throw error;
                });
        };
    },
    assignPayment(payment) {
        return async (dispatch, getState, { defaultFetch, services }) => {
            const { info } = getState().cart;
            const cart = info?.cart;
            if (cart) {
                const data = {
                    cart,
                    monolithType: +payment.setup.code,
                    paymentMethod: payment['@id'],
                    description: payment.description,
                };
                const url = `${services.cart.domain}${services.cart.assignPayment}`;
                defaultFetch
                    .post(url, data)
                    .then(() => {
                        dispatch(OrderActions.setReadyField(CheckoutFields.payment, true));
                    })
                    .catch(() => {
                        dispatch(OrderActions.setReadyField(CheckoutFields.payment, false));
                    });
            }
        };
    },
    setPayments(payment) {
        return {
            type: Types.SET_PAYMENTS,
            payload: payment,
        };
    },
};
