import { message } from 'antd';

import { cleanTokens } from '../../utils/httpClient';
import { TAction } from '../store';

export const Types = {
    SET_CONTAINER_QUANTITY: 'ReturnedContainers@SET:QUANTITY',
};

export type TSetQuantityAction = {
    type: typeof Types.SET_CONTAINER_QUANTITY;
    payload: number;
};

type TEnrichToken = {
    '@context': string;
    '@id': string;
    '@type': string;
    id: number;
    partnerId: string | null;
    token: string;
};

export type TReturnedContainers = TSetQuantityAction;

type ReturnedContainersType = {
    partnerEnrichToken: () => TAction<Promise<void>>;
};

export const PartnerActions: ReturnedContainersType = {
    partnerEnrichToken() {
        return async (_, getState, { defaultFetch, services }) => {
            const url = `${services.partner.domain}${services.partner.enrichToken}`;
            const { info } = getState().company;

            if (info) {
                const partnerId = info.partnerId;

                return defaultFetch
                    .post<TEnrichToken>(url, { partnerId: partnerId })
                    .then(({ data }) => {
                        return localStorage.setItem('token', data.token);
                    })
                    .catch(e => {
                        message.error(e.message);
                        cleanTokens();
                    });
            }
        };
    },
};
