import { TFormOrder } from '../../routes/cart/hooks/useOrder';
import NetworkStatus from '../../utils/enums/NetworkStatus';
import { PaymentCodes } from '../../utils/enums/PaymentCodes';
import {
    CheckoutFields,
    OrderNetworkItems,
    PromocodeStatus,
    TPromocodeStatus,
} from '../reducers/order';
import { TAction } from '../store';
import { TIntervalActions, Types as TypesInterval } from './intervals';
import { TPaymentActions, Types as TypesPayment } from './payment';

export const Types = {
    ...TypesInterval,
    ...TypesPayment,
    SET_READY_FIELD: 'ORDER@SET:READY:FIELD',
    SET_DEFAULT_FIELD: 'ORDER@SET:DEFAULT:FIELD',
    SET_NETWORK_STATUS: 'ORDER@SET:NETWORK:STATUS',
    SET_PROMOCODE_STATUS: 'ORDER@SET:PROMOCODE:STATUS',
};

export type TSetReadyField = {
    type: typeof Types.SET_READY_FIELD;
    payload: Record<CheckoutFields, boolean>;
};

export type TSetDefaultField = {
    type: typeof Types.SET_READY_FIELD;
    payload: Record<CheckoutFields, any>;
};

export type TSetNetworkStatus = {
    type: typeof Types.SET_READY_FIELD;
    payload: Record<CheckoutFields, boolean>;
};

export type TSetPromocodeStatus = {
    type: typeof Types.SET_PROMOCODE_STATUS;
    payload: TPromocodeStatus;
};

export type TOrderActions =
    | TIntervalActions
    | TPaymentActions
    | TSetReadyField
    | TSetPromocodeStatus;

type TTokenResponse = {
    '@context': string;
    '@id': string;
    '@type': string;
    basicLegalPerson: string;
    basicLegalPersonId: string;
    familyName: null | string;
    firstName: string;
    id: number;
    middleName: null | string;
    partnerId: string;
    phone: string;
    token: string;
    username: string;
};

type TOrderResponse = {
    '@context': string;
    '@id': string;
    '@type': string;
    cart: string;
    id: string;
    createdAt: string;
    order: string;
    orderDetails: {
        '@type': string;
        '@id': string;
        id: number;
        source: string;
        status: string;
        paymentType: string;
        orderType: string;
        deliveryCost: number;
        coinsAmount: number;
        createdAt: string;
        updatedAt: string;
        shippingDate: string;
        shippingDateTo: string;
        items: {
            '@type': string;
            '@id': string;
            product: {
                '@type': string;
                '@id': string;
                uuid: string;
                id: number;
                name: string;
                description: string;
                image: string;
            };
            quantity: number;
            price: number;
        }[];
        total: number;
        currency: string;
        address: {
            '@type': string;
            '@id': string;
            identity: {
                '@type': string;
                '@id': string;
                serviceUuid: string;
                monolithUuid: string;
                monolithId: number;
            };
            city: string;
            street: string;
            house: string;
            coordinates: {
                '@type': string;
                '@id': string;
                latitude: number;
                longitude: number;
            };
            name: string;
            cityId: number;
            districtId: number;
        };
        client: {
            '@type': string;
            '@id': string;
            phoneNumber: string;
            name: string;
            status: string;
            type: string;
            contractNumber: number;
            createdAt: string;
            updatedAt: string;
            externalId: string;
            email: string;
            partnerId: string;
            uuid: string;
        };
        payment: {
            '@type': string;
            '@id': string;
            partnerId: string;
            documentId: string;
            currency: string;
            amount: number;
            amountMoney: {
                amount: string;
                currency: string;
            };
            date: string;
            clientId: string;
            status: string;
            orderNumber: number;
        };
        timeZone: string;
        crmUrl: string;
        returnableContainerQuantity: number;
        legalPersonId: string;
        paymentMethodId: string;
        context: {
            routeUrl: string;
            items: {
                quantity: number;
                price: number;
                text: string;
            }[];
            discount: null;
        };
        partnerId: string;
        uuid: string;
    };
};

export type TAssignPromocodeResponse = {
    '@context': string;
    '@id': string;
    '@type': string;
    cart: string;
    createdAt: string;
    errorMessage: string;
    id: string;
    promotionCode: string;
    valid: boolean;
};

type OrderActionsType = {
    assignNote: (value: string) => TAction<Promise<void>>;
    assignPromocode: (promocode: string) => TAction<Promise<void>>;
    setPromocodeStatus: (status: TPromocodeStatus) => TSetPromocodeStatus;
    completeOrder: (data: TFormOrder) => TAction<Promise<void>>;
    sendOnlinePayment: (data: TOrderResponse) => TAction<Promise<void>>;
    setReadyField: (fieldName: CheckoutFields, value: boolean) => TAction<Promise<void>>;
    setDefaultField: (fieldName: CheckoutFields, value: any) => TAction<Promise<void>>;
    setNetworkStatus: (
        networkItem: OrderNetworkItems,
        status: NetworkStatus,
    ) => TAction<Promise<void>>;
};

export const OrderActions: OrderActionsType = {
    assignNote(value) {
        return async (dispatch, getState, { defaultFetch, services }) => {
            const { info } = getState().cart;
            const cart = info?.cart;
            if (cart) {
                const data = {
                    cart,
                    note: value,
                };
                const url = `${services.cart.domain}${services.cart.assignNote}`;
                defaultFetch
                    .post(url, data)
                    .then(() => {
                        dispatch(this.setReadyField(CheckoutFields.note, true));
                    })
                    .catch(() => {
                        dispatch(this.setReadyField(CheckoutFields.note, false));
                    });
            }
        };
    },
    assignPromocode(value) {
        return async (dispatch, getState, { defaultFetch, services }) => {
            const { info } = getState().cart;
            const cart = info?.cart;
            if (cart) {
                const data = {
                    cart,
                    promotionCode: value,
                };
                if (value === '') {
                    dispatch(this.setPromocodeStatus(null));
                }
                const url = `${services.cart.domain}${services.cart.assignPromocode}`;
                return defaultFetch.post<TAssignPromocodeResponse>(url, data).then(({ data }) => {
                    if (value !== '') {
                        const status = data.valid ? PromocodeStatus.Valid : PromocodeStatus.Invalid;
                        dispatch(this.setPromocodeStatus(status));
                    }
                });
            }
        };
    },
    setPromocodeStatus(status) {
        return {
            type: Types.SET_PROMOCODE_STATUS,
            payload: status,
        };
    },
    sendOnlinePayment(order) {
        return async (_, getState, { defaultFetch, services }) => {
            const { info } = getState().company;
            const { data: userData } = getState().user;
            if (userData && info) {
                const partnerId = info.partnerId;
                const url = `${services.partner.domain}${services.partner.getPartnerToken}`;
                const data = {
                    partnerId,
                    username: userData.email,
                    firstName: userData.username || userData.phone_number,
                    phone: userData.phone_number,
                };
                defaultFetch.post<TTokenResponse>(url, data).then(({ data }) => {
                    const url = `${services.payment.domain}${services.payment.paymentRequest}`;
                    const requestData = {
                        documentId: order.orderDetails.uuid,
                        orderNumber: order.orderDetails.payment.orderNumber,
                        clientId: order.orderDetails.payment.clientId,
                        clientEmail: userData.email,
                        amount: order.orderDetails.total,
                        context: order.orderDetails.context,
                        returnUrl: 'http://localhost:3000/cart?success=true',
                        clientPhone: userData.phone_number,
                        paymentMethod: order.orderDetails.paymentMethodId,
                        description: 'Зашитый description',
                        clientAddress: order.orderDetails.address.name,
                        currency: order.orderDetails.currency,
                        autoRedirect: false,
                    };
                    defaultFetch
                        .post(url, requestData, {
                            headers: {
                                Authorization: `Bearer ${data.token}`,
                            },
                        })
                        .then(({ data }) => {
                            window.location.replace(data.paymentUrl);
                        });
                });
            }
        };
    },
    completeOrder(formData) {
        return async (dispatch, getState, { orderFetch, services }) => {
            const onlineCodes = [PaymentCodes.online1, PaymentCodes.online2];
            const isReadyFields = getState().order.isReadyFields;
            const { info } = getState().cart;
            const cart = info?.cart;
            const canComplete = Object.values(isReadyFields).every(field => field === true);
            if (canComplete && cart) {
                const url = `${services.cart.domain}${services.cart.complete}`;
                const data = {
                    cart,
                };
                orderFetch
                    .post<TOrderResponse>(url, data)
                    .then(({ data }) => {
                        const { payments } = getState().order;
                        const payment = payments.find(
                            payment => formData.payment === payment['@id'],
                        );
                        if (payment?.setup.code && onlineCodes.includes(payment.setup.code)) {
                            dispatch(this.sendOnlinePayment(data));
                        }
                    })
                    .catch(error => console.log(error));
            }
        };
    },
    setNetworkStatus(networkItem, status) {
        return async (dispatch, getState) => {
            const networkItems = getState().order.networkItems;
            dispatch({
                type: Types.SET_NETWORK_STATUS,
                payload: { ...networkItems, [networkItem]: status },
            });
        };
    },
    setReadyField(fieldName, value) {
        return async (dispatch, getState) => {
            const isReadyFields = getState().order.isReadyFields;
            dispatch({
                type: Types.SET_READY_FIELD,
                payload: { ...isReadyFields, [fieldName]: value },
            });
        };
    },
    setDefaultField(fieldName, value) {
        return async (dispatch, getState) => {
            const defaultFields = getState().order.defaultFields;
            dispatch({
                type: Types.SET_DEFAULT_FIELD,
                payload: { ...defaultFields, [fieldName]: value },
            });
        };
    },
};
