import NetworkStatus from '../../utils/enums/NetworkStatus';
import { TAddressesActions, Types, TAddress } from '../actions/addresses';

export declare type AddressesState = {
    addresses: TAddress[];
    selectedAddress: TAddress;
    networkStatus: NetworkStatus;
};

const address = {
    id: 12177,
    external_id: 'd862ffa7-822e-4c94-9214-72654a6d0c9f',
    uuid: 'd862ffa7-822e-4c94-9214-72654a6d0c9f',
    user_id: 12936,
    name: 'Коммунистическая улица, д. 35, г. Сыктывкар – Коммунистическая',
    city: 'Сыктывкар',
    city_id: 1,
    district: null,
    district_id: 8,
    street: 'Коммунистическая улица',
    house: '35',
    housing: '',
    building: '',
    entrance: '',
    floor: '',
    room: null,
    comment: null,
    loyalty_system: 1,
    longitude: '50.81374814',
    latitude: '61.66231275',
    valid_for_delivery: true,
    coordinates: {
        longitude: '50.81374814',
        latitude: '61.66231275',
    },
};

const initState: AddressesState = {
    addresses: [address],
    selectedAddress: address,
    networkStatus: NetworkStatus.pending,
};

const addresses = (state = initState, action: TAddressesActions): AddressesState => {
    let newState;
    switch (action.type) {
        case Types.SET_ADDRESS:
            newState = { ...state, addresses: action.payload };
            break;
        case Types.SET_SELECTED_ADDRESS:
            newState = { ...state, selectedAddress: action.payload };
            break;
        case Types.SET_NETWORK_STATUS:
            newState = { ...state, networkStatus: action.payload };
            break;
    }

    return (newState as any) || state;
};

export default addresses;
