export default {
    header: {
        company: 'Company',
        user: 'User',
        subtitle: 'Delivery in',
        buttonBack: 'Back',
        buttonCheckout: 'Checkout',
    },
    mobileMenu: {
        company: 'Company settings',
        user: 'My profile',
    },
    footer: {
        contractOffer: 'User agreement',
        privacyPolicy: 'Privacy policy',
        partnerWebsite: 'App Solution partner website',
    },
    buttons: {
        logout: 'Sign out',
    },
};
