import React from 'react';

import ReactDOM from 'react-dom';

import App, { AppLoader } from './App';
import { initConfig } from './config';
import * as serviceWorker from './serviceWorker';
import { fetchAppConfig } from './utils/appConfig';

import './index.css';

const renderApp = async () => {
    await fetchAppConfig();
    initConfig();
    ReactDOM.render(<App />, document.getElementById('root'));
};

const timer: NodeJS.Timeout = setInterval(() => {
    ReactDOM.render(<AppLoader />, document.getElementById('root'));
    //@ts-ignore
    if (window.env) {
        renderApp();
        clearInterval(timer);
    }
}, 200);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
