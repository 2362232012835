import Axios from 'axios';
import { batch } from 'react-redux';

import NetworkStatus from '../../utils/enums/NetworkStatus';
import URLHelper from '../../utils/URLHelper';
import { TAction } from '../store';
import { CartActions, TCartItem } from './cart';
import { ReturnedContainers } from './returnedContainers';

export const Types = {
    FETCH_HISTORY: 'HISTORY@FETCH:HISTORY',
    SET_HISTORIES: 'HISTORY@SET:HISTORIES',
    SET_PAGINATION: 'HISTORY@SET:PAGINATION',
    SET_NETWORK_STATUS: 'HISTORY@SET:NETWORK:STATUS',
};

export type THistoryProduct = {
    id: number;
    productId: number;
    productName: string;
    quantity: number;
    productPrice: {
        amount: string;
        currency: string;
    };
    image: string;
};

export type THistory = {
    id: number;
    uuid: string;
    contractNumber: number;
    status: string;
    paymentType: string;
    products: THistoryProduct[];
    productSets: [];
    total: {
        amount: string;
        currency: string;
    };
    deliveryCost: {
        amount: string;
        currency: string;
    };
    paidAmount: {
        amount: string;
        currency: string;
    };
    coinsAmount: {
        amount: string;
        currency: string;
    };
    createdAt: {
        date: string;
        timezone_type: number;
        timezone: string;
    };
    shippingDate: {
        date: string;
        timezone_type: number;
        timezone: string;
    };
    shippingDateTo: {
        date: string;
        timezone_type: number;
        timezone: string;
    };
    completedAt: {
        date: string;
        timezone_type: number;
        timezone: string;
    };
    shipping: {
        address: string;
        date: string;
        interval: string;
    };
    paymentStatus: string;
    paymentUrl: null;
    coinCashBack: number;
    coinAccrued: false;
    clientComment: null;
    context: {
        routeUrl: string;
        items: [
            {
                quantity: number;
                price: number;
                text: string;
            },
        ];
        discount: string;
    };
};

export type TSetHistoryItems = {
    type: typeof Types.SET_HISTORIES;
    payload: Record<number, THistory[]>;
};

export type TSetHistoryPagination = {
    type: typeof Types.SET_PAGINATION;
    payload: TPagination;
};

export type TSetNetworkStatus = {
    type: typeof Types.SET_NETWORK_STATUS;
    payload: NetworkStatus;
};

export type TPagination = {
    total_count: number;
    count_per_page: number;
    current_page_number: number;
    has_next_page: boolean;
};

type TFetchHistoryResponse = {
    success: boolean;
    pagination: TPagination;
    data: THistory[];
};
export type THistoriesActions = TSetHistoryItems | TSetHistoryPagination;

type TReorderResponse = {
    '@context': string;
    '@id': string;
    '@type': string;
    cart: string;
    createdAt: string;
    id: string;
    order: string;
};

type HistoriesActionsType = {
    fetchHistories: (page?: number, count?: number) => TAction<Promise<void>>;
    addOrder: (history: THistory) => TAction<Promise<void>>;
    setHistories: (histories: Record<number, THistory[]>) => TSetHistoryItems;
    setPagination: (pagination: TPagination) => TSetHistoryPagination;
    setNetworkStatus: (status: NetworkStatus) => TSetNetworkStatus;
};

export const HistoriesAction: HistoriesActionsType = {
    fetchHistories(page, count) {
        return async (dispatch, getState, { defaultFetch, api }) => {
            if (!page) {
                page = 1;
            }
            const items = getState().history.items;
            if (items && items[page]) {
                return;
            }
            dispatch(this.setNetworkStatus(NetworkStatus.loading));
            const url = URLHelper.buildUrl(api.getHistories, {
                page,
                count: count || 10,
            });
            defaultFetch.get<TFetchHistoryResponse>(url).then(({ data }) => {
                if (data.success) {
                    const histories = getState().history.items || {};
                    histories[page || 1] = data.data;
                    batch(() => {
                        dispatch(this.setHistories(histories));
                        if (data.pagination.current_page_number === 1) {
                            dispatch(this.setPagination(data.pagination));
                        }
                        dispatch(this.setNetworkStatus(NetworkStatus.ready));
                    });
                }
            });
        };
    },
    addOrder(history) {
        return async (dispatch, getState, services) => {
            const { cart: cartService } = services.services;
            const { catalog } = getState().catalog;
            const products = catalog.map(item => item.products).flat();
            const url = `${cartService.domain}${cartService.reorder}`;
            const data = {
                externalOrder: `/order/external_orders/${history.uuid}`,
            };
            const cartProducts: Record<number, TCartItem> = {};
            let returnedContainers = 0;
            history.products.forEach(hProduct => {
                const product = products.find(product => product.id === hProduct.productId);
                if (product) {
                    if (product.type === 'Вода') {
                        returnedContainers += 1;
                    }
                    cartProducts[product.id] = {
                        productId: product.id,
                        quantity: hProduct.quantity,
                        type: product.type,
                    };
                }
            });
            await services.orderFetch
                .post<TReorderResponse>(url, data)
                .then(({ data }) => {
                    batch(() => {
                        dispatch(CartActions.setInfoCart(data));
                        dispatch(CartActions.setCartItems(cartProducts));
                        dispatch(ReturnedContainers.setQuantity(returnedContainers));
                        dispatch(ReturnedContainers.setMaxQuantity(returnedContainers));
                        dispatch(CartActions.setCartItems(cartProducts));
                    });
                })
                .catch(error => {
                    if (Axios.isCancel(error)) {
                        return;
                    }
                    throw error;
                });
        };
    },
    setHistories(histories) {
        return {
            type: Types.SET_HISTORIES,
            payload: histories,
        };
    },
    setPagination(pagination) {
        return {
            type: Types.SET_PAGINATION,
            payload: pagination,
        };
    },
    setNetworkStatus(status) {
        return {
            type: Types.SET_NETWORK_STATUS,
            payload: status,
        };
    },
};
