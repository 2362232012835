import React, { FC } from 'react';

import { YMInitializer } from 'react-yandex-metrika';
import { default as yandexMetrika } from 'react-yandex-metrika';

type YandexMetrikaMethod =
    | 'addFileExtension'
    | 'extLink'
    | 'file'
    | 'getClientID'
    | 'hit'
    | 'notBounce'
    | 'params'
    | 'reachGoal'
    | 'replacePhones'
    | 'setUserID'
    | 'userParams';

type YandexAnalyticsProps = {
    accounts: number[];
    ymInitializerOptions?: Record<string, unknown>;
    ymOptions?: Record<string, unknown>;
    visitParams?: Record<string, unknown>;
    userParams?: Record<string, unknown>;
};

const YandexAnalytics: FC<YandexAnalyticsProps> & {
    event: (method: YandexMetrikaMethod, ...params: unknown[]) => void;
} = ({ children, accounts, ymInitializerOptions, ymOptions, visitParams, userParams }) => {
    return (
        <>
            {children}
            <YMInitializer
                accounts={accounts}
                options={{ params: visitParams, userParams, ...ymOptions }}
                {...ymInitializerOptions}
            />
        </>
    );
};

YandexAnalytics.event = (method, ...params) => {
    yandexMetrika(method, ...params);
};

export default YandexAnalytics;
