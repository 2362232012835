import { message } from 'antd';
import axios, { AxiosRequestConfig } from 'axios';

import { AppConfig } from '../entities/AppConfigEntity';

export const fetchAppConfig = async () => {
    const fetch = () => {
        const setDomain = () => {
            const removeSpaces = (str?: string) => str?.replace(/\s+/g, '');

            if (window.location.hostname === 'localhost') {
                switch (removeSpaces(process.env.REACT_APP_ENV)) {
                    case 'demo':
                        return 'client.appsol.ru';
                    default:
                        return 'client.dev.appsol.ru';
                }
            } else return window.location.hostname;
        };

        const origin = 'https://functions.yandexcloud.net';
        const path = 'd4eat880usspg104l6lj';

        const url = new URL(path + '?', origin).toString();

        const searchParams = new URLSearchParams({
            domain: setDomain(),
            version: process.env.REACT_APP_VERSION || '',
        }).toString();

        const config: AxiosRequestConfig = { timeout: 30000 };

        return axios.get<AppConfig>(url + searchParams, config);
    };

    try {
        const response = await fetch();

        localStorage.setItem('config', JSON.stringify(response.data));
    } catch (e) {
        if (e instanceof Error) {
            message.error(e.message);
        }
    }
};

export const getAppConfig: () => AppConfig = () => {
    return JSON.parse(localStorage.getItem('config') || 'null');
};
