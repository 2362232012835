import { message } from 'antd';
import Axios from 'axios';
import { batch } from 'react-redux';

import NetworkStatus from '../../utils/enums/NetworkStatus';
import { decodeToken, isAnonToken } from '../../utils/token';
import { CheckoutFields } from '../reducers/order';
import { TAction } from '../store';
import { OrderActions } from './order';

export const Types = {
    SET_ADDRESS: 'ADDRESSES@SET:ADDRESSES',
    SET_SELECTED_ADDRESS: 'ADDRESSES@SET:SELECTED:ADDRESS',
    SET_NETWORK_STATUS: 'ADDRESSES@SET:NETWORK:STATUS',
};

export type TSetAddresses = {
    type: typeof Types.SET_ADDRESS;
    payload: TAddress[];
};

export type TSetSelectedAddress = {
    type: typeof Types.SET_ADDRESS;
    payload: any;
};

export type TSetNetworkStatus = {
    type: typeof Types.SET_NETWORK_STATUS;
    payload: NetworkStatus;
};

export type TAddressesActions = TSetAddresses;

type AddressesActionsType = {
    fetchAddresses: () => TAction<Promise<void>>;
    assignAddress: (address: TAddress) => TAction<Promise<void>>;
    addAddress: (addresses: TAddress) => TAction<Promise<void>>;
    setAddresses: (addresses: TAddress[]) => TSetAddresses;
    setSelectedAddress: (address: TAddress) => TSetSelectedAddress;
    setNetworkStatus: (status: NetworkStatus) => TSetNetworkStatus;
};

export type TAddress = {
    building: string;
    city: string;
    city_id: number;
    comment: string | null;
    coordinates: {
        latitude: string;
        longitude: string;
    };
    district: string | null;
    district_id: number;
    entrance: string;
    external_id: string;
    floor: string;
    house: string;
    housing: string;
    id: number;
    latitude: string;
    longitude: string;
    loyalty_system: number;
    name: string;
    room: string | null;
    street: string;
    user_id: number;
    uuid: string;
    valid_for_delivery: boolean;
    note?: string | null;
};

type TAddressResponse = {
    data: TAddress[];
};

export type TAddressAssign = {
    cart: string;
    latitude: number;
    longitude: number;
    addressId?: string;
    name?: string;
    city?: string;
    street?: string;
    building?: string;
    room?: string;
    note?: string;
};

const errorHandler = (error: any, defaultMessage: string) => {
    if (Axios.isCancel(error)) {
        return;
    }
    const errorMessage = error?.message || defaultMessage;
    message.error(errorMessage);
};

export const AddressesActions: AddressesActionsType = {
    fetchAddresses() {
        return async (dispatch, _, { api, defaultFetch }) => {
            dispatch(this.setNetworkStatus(NetworkStatus.loading));
            await defaultFetch
                .get<TAddressResponse>(api.getAddresses)
                .then(({ data }) => {
                    batch(() => {
                        dispatch(this.setNetworkStatus(NetworkStatus.ready));
                        if (data.data.length > 0) {
                            dispatch(this.setAddresses(data.data));
                            dispatch(this.setSelectedAddress(data.data[0]));
                        }
                    });
                })
                .catch(e => errorHandler(e, 'Failed to fetch addresses'));
        };
    },
    assignAddress(address) {
        return async (dispatch, getState, { services, defaultFetch }) => {
            const { info } = getState().cart;
            const cart = info?.cart;

            if (cart) {
                const url = `${services.cart.domain}${services.cart.assignAddress}`;
                const token = localStorage.getItem('token');
                const isAuth =
                    getState().user.data !== null &&
                    token &&
                    !isAnonToken(decodeToken(token).roles);
                const data: TAddressAssign = {
                    cart,
                    latitude: +address.coordinates.latitude,
                    longitude: +address.coordinates.longitude,
                };

                if (isAuth) {
                    data['addressId'] = address.external_id;
                } else {
                    data['name'] = address.name;
                    data['city'] = address.city;
                    data['street'] = address.street;
                    data['building'] = address.building || address.house;
                    data['room'] = address.room || '';
                    data['note'] = address.note || '';
                }

                await defaultFetch
                    .post(url, data)
                    .then(() => {
                        dispatch(OrderActions.setReadyField(CheckoutFields.address, true));
                    })
                    .catch(e => {
                        dispatch(OrderActions.setReadyField(CheckoutFields.address, false));
                        errorHandler(e, 'Failed to assign address');
                    });
            }

            return;
        };
    },
    addAddress(address) {
        return async (dispatch, getState) => {
            const { addresses } = getState().addresses;
            dispatch(this.setAddresses([...addresses, address]));
        };
    },
    setSelectedAddress(address) {
        return {
            type: Types.SET_SELECTED_ADDRESS,
            payload: address,
        };
    },
    setAddresses(addresses) {
        return {
            type: Types.SET_ADDRESS,
            payload: addresses,
        };
    },
    setNetworkStatus(status) {
        return {
            type: Types.SET_NETWORK_STATUS,
            payload: status,
        };
    },
};
