import { TAction } from '../store';

export const Types = {
    SET_COMPANY_INFO: 'COMPANY@SET:INFO',
};

export type TSetCompanyInfo = {
    type: typeof Types.SET_COMPANY_INFO;
    payload: any;
};
export type TCompanies = TSetCompanyInfo;

type TCompaniesType = {
    fetchCompanyInfo: () => TAction<Promise<void>>;
    setCompanyInfo: (data: any) => TSetCompanyInfo;
};

export const CompanyActions: TCompaniesType = {
    fetchCompanyInfo() {
        return async (dispatch, getState, { defaultFetch, api }) => {
            defaultFetch.get(api.about).then(({ data }) => {
                dispatch(this.setCompanyInfo(data));
            });
        };
    },
    setCompanyInfo(data) {
        return {
            type: Types.SET_COMPANY_INFO,
            payload: data,
        };
    },
};
