import { FieldRenderProps } from 'react-final-form';
import { Dayjs } from 'dayjs';

export type TFieldRenderProps<Value> = FieldRenderProps<Value, any>;

export type Period = {
    from: Dayjs;
    to: Dayjs;
};

export enum NamedPeriod {
    Days = 'days',
    Weeks = 'weeks',
    Months = 'months',
}

export type TokenPayload = {
    iat: number;
    roles: UserRoles[];
    username: string;
    exp: number;
    temp: boolean;
    uuid: string;
    first_name: string | null;
    middle_name: string | null;
    family_name: string | null;
    partner_id?: string;
    legal_person_id?: string;
};

export enum UserRoles {
    RoleUser = 'ROLE_USER',
    RoleAdmin = 'ROLE_ADMIN',
    RoleClient = 'ROLE_CLIENT',
    AnonymousAggregatorClient = 'ANONYMOUS_AGGREGATOR_CLIENT',
    RoleAggregatorUser = 'ROLE_AGGREGATOR_USER',
    RoleOwner = 'ROLE_OWNER',
    RoleAnonymousPartnerClient = 'ROLE_ANONYMOUS_PARTNER_CLIENT',
    RoleClientApp = 'ROLE_CLIENT_APP',
}
