export default {
    title: 'Бонусные баллы',
    login: 'Войдите или зарегистриуйтесь, чтобы получать баллы',
    marketing:
        '1 балл = 1 рубль Как получить бонусы? Рядом с ценой каждого товара указано количество баллов, которое вы получите при покупке этого товара. Бонусы начисляются после успешной доставки заказа (за отменённые заказы и за заказы, полностью или частично оплаченные баллами, бонусы не начисляются). Как потратить бонусы? Оплачивайте покупки баллами при оформлении заказа через приложение.',
    download: {
        start: 'Скачайте приложение',
        end: 'чтобы использовать бонусы для оплаты',
    },
};
