import { batch } from 'react-redux';

import { TAction } from '../store';
import { CartActions } from './cart';

export const Types = {
    SET_CONTAINER_QUANTITY: 'ReturnedContainers@SET:QUANTITY',
    SET_CONTAINER_MAX_QUANTITY: 'ReturnedContainers@SET:MAX_QUANTITY',
};

export type TSetQuantityAction = {
    type: typeof Types.SET_CONTAINER_QUANTITY;
    payload: number;
};

export type TSetMaxQuantityAction = {
    type: typeof Types.SET_CONTAINER_MAX_QUANTITY;
    payload: number;
};

type TReturnedContainer = {
    '@context': string;
    '@id': string;
    '@type': string;
    cart: string;
    createdAt: string;
    id: string;
    quantity: number;
};

export type TReturnedContainers = TSetQuantityAction;

type ReturnedContainersType = {
    getMaxQuantityWater: () => TAction<Promise<void>>;
    addReturnedContainer: (quantity: number) => TAction<Promise<void>>;
    reduceReturnedContainer: (quantity: number) => TAction<Promise<void>>;
    setQuantity: (quantity: number) => TSetQuantityAction;
    setMaxQuantity: (quantity: number) => TSetMaxQuantityAction;
};

export const ReturnedContainers: ReturnedContainersType = {
    getMaxQuantityWater() {
        return async (dispatch, getState) => {
            const { items } = getState().cart;
            if (items) {
                const waterProducts = Object.values(items).filter(item => item.type === 'Вода');
                const waterCount = waterProducts.reduce(
                    (acc, current) => acc + current.quantity,
                    0,
                );
                dispatch(this.setMaxQuantity(waterCount));
            }
        };
    },
    addReturnedContainer(quantity) {
        return async (dispatch, getState, { defaultFetch, services }) => {
            const url = `${services.cart.domain}${services.cart.addReturnableContainer}`;
            const { info } = getState().cart;
            const cart = info?.cart;
            if (cart) {
                const data = {
                    cart,
                    quantity,
                };
                defaultFetch.post<TReturnedContainer>(url, data).then(({ data }) => {
                    batch(() => {
                        dispatch(CartActions.calculate());
                        dispatch(this.setQuantity(data.quantity));
                        dispatch(this.getMaxQuantityWater());
                    });
                });
            }
        };
    },
    reduceReturnedContainer(quantity) {
        return async (dispatch, getState, { defaultFetch, services }) => {
            const url = `${services.cart.domain}${services.cart.reduceReturnableContainer}`;
            const { info } = getState().cart;
            const cart = info?.cart;
            if (cart) {
                const data = {
                    cart,
                    quantity,
                };
                defaultFetch.post<TReturnedContainer>(url, data).then(({ data }) => {
                    const { maxQuantity } = getState().returnedContainers;
                    batch(() => {
                        dispatch(this.setQuantity(data.quantity));
                        dispatch(this.getMaxQuantityWater());
                        if (maxQuantity - data.quantity === 0) {
                            dispatch(CartActions.calculate());
                        }
                    });
                });
            }
        };
    },
    setQuantity(quantity) {
        return {
            type: Types.SET_CONTAINER_QUANTITY,
            payload: quantity,
        };
    },
    setMaxQuantity(quantity) {
        return {
            type: Types.SET_CONTAINER_MAX_QUANTITY,
            payload: quantity,
        };
    },
};
