import { TCompany } from '../../entities/CompanyEntity';
import { TCompanies, Types } from '../actions/company';

export declare type TCompanyState = {
    info: TCompany | null;
};

const initState: TCompanyState = {
    info: null,
};

const company = (state = initState, action: TCompanies): TCompanyState => {
    let newState;
    switch (action.type) {
        case Types.SET_COMPANY_INFO:
            newState = { ...state, info: action.payload };
            break;
    }
    return (newState as any) || state;
};

export default company;
