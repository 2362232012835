import NetworkStatus from '../../utils/enums/NetworkStatus';
import { TPeriod } from '../actions/intervals';
import { Types, TOrderActions } from '../actions/order';
import { TPayment } from '../actions/payment';

export declare type OrderState = {
    intervals: TPeriod[];
    payments: TPayment[];
    isReadyFields: Record<CheckoutFields, boolean>;
    defaultFields: Record<CheckoutFields, any>;
    networkItems: Record<OrderNetworkItems, NetworkStatus>;
    promocodeStatus: TPromocodeStatus;
};

export enum PromocodeStatus {
    Valid = 'valid',
    Invalid = 'invalid',
}

export type TPromocodeStatus = PromocodeStatus | null;

export type OrderNetworkItems = 'intervals' | 'payments';

export enum CheckoutFields {
    address = 'AddressField',
    interval = 'IntervalField',
    payment = 'PaymentField',
    note = 'NoteField',
}

const initState: OrderState = {
    intervals: [],
    payments: [],
    isReadyFields: {
        AddressField: false,
        IntervalField: false,
        PaymentField: false,
        NoteField: false,
    },
    defaultFields: {
        AddressField: null,
        IntervalField: null,
        PaymentField: null,
        NoteField: null,
    },
    networkItems: {
        intervals: NetworkStatus.pending,
        payments: NetworkStatus.pending,
    },
    promocodeStatus: null,
};

const Cart = (state = initState, action: TOrderActions): OrderState => {
    let newState;
    switch (action.type) {
        case Types.SET_INTERVALS:
            newState = { ...state, intervals: action.payload };
            break;
        case Types.SET_PAYMENTS:
            newState = { ...state, payments: action.payload };
            break;
        case Types.SET_NETWORK_STATUS:
            newState = { ...state, networkItems: action.payload };
            break;
        case Types.SET_READY_FIELD:
            newState = { ...state, isReadyFields: action.payload };
            break;
        case Types.SET_DEFAULT_FIELD:
            newState = { ...state, defaultFields: action.payload };
            break;
        case Types.SET_PROMOCODE_STATUS:
            newState = { ...state, promocodeStatus: action.payload };
            break;
    }
    return (newState as any) || state;
};

export default Cart;
