export default {
    aboutCompany: {
        title: 'О компании',
    },
    deliveryPayment: {
        title: 'Доставка и оплата',
        shippingTime: 'Режим работы доставки:',
        titlePaymentMethods: 'Способы оплаты:',
        paymentMethods: {
            offlineAcquiring: 'Банковский картой',
            cash: 'Наличными',
            googlePay: 'Google Pay',
            applePay: 'Apple Pay ',
            sberbank: 'Сбербанк',
            loyalty: 'Оплата баллами',
            contract: 'По договору',
            eximbank: 'Эксимбанк',
            sberbankOnlineByPhone: 'СберPay',
            liqpay: 'Liqpay',
            tinkoff: 'Тинькофф',
        },
    },
    downloadAppModal: {
        title: 'Скачать приложение',
        text: 'Скачай приложение и делай заказы удобно!',
        textMobile: 'Скачайте приложение и делайте заказы удобно',
    },
};
