import { message } from 'antd';
import Axios from 'axios';
import { batch } from 'react-redux';

import NetworkStatus from '../../utils/enums/NetworkStatus';
import { refreshToken } from '../../utils/httpClient';
import { TAction } from '../store';
import { AddressesActions } from './addresses';
import { CartActions } from './cart';

export const Types = {
    SET_USER: 'USER@SET:USER',
    SET_NETWORK_STATUS: 'USER@SET:NETWORK:STATUS',
};

export type TSetUserAction = {
    type: typeof Types.SET_USER;
    payload: any;
};

export type TSetNetworkStatusAction = {
    type: typeof Types.SET_NETWORK_STATUS;
    payload: NetworkStatus;
};

export type TUser = TSetUserAction | TSetNetworkStatusAction;

type TRegUser = {
    email: string | null;
    phoneNumber: string | null;
    firstName: string;
    familyName: string;
};

type UserType = {
    fetchUser: () => TAction<Promise<void>>;
    fetchProfile: () => TAction<Promise<void>>;
    register: (values: TRegUser) => TAction<Promise<void>>;
    logoutUser: () => TAction<Promise<void>>;
    assignUser: (phone: string, fio: string) => TAction<Promise<void>>;
    setUser: (data: TUserData | TUserAnonData | null) => TSetUserAction;
    setNetworkStatus: (status: NetworkStatus) => TSetNetworkStatusAction;
};

export type TUserAnonData = {
    uuid?: string;
    email: string | null;
    username: string;
    phone_number: string;
};

export type TUserData = {
    id: number;
    external_id: string;
    uuid: string;
    username: string;
    comment: string | null;
    email: string;
    status: number;
    created_at: string;
    updated_at: string;
    phone_number: string;
    phone: string;
    raw_phone: string | null;
    contract_number: number;
    type: number;
    legal_address: string | null;
    user_source: string;
    date_last_use_mobile_app: string;
    notify_make_order_date: string;
    invite_code: string;
    loyalty_system: number;
    city_id: number;
    do_not_call: number;
    after_purchase_registration_date: string | null;
    password_reset_date_for_aggregator: string | null;
    last_phone_call_date: string | null;
    chat_settings: string;
    address: string;
    coins: number;
    show_order_statuses: boolean;
    about_invitation_process_url: string;
    invite_code_qr_uri: string;
    text_for_invite_code: string;
    roles: {
        guest: {
            type: number;
            name: string;
            description: string | null;
            ruleName: string | null;
            data: string | null;
            createdAt: string | null;
            updatedAt: string | null;
        };
        customer: {
            type: string;
            name: string;
            description: string;
            ruleName: null;
            data: null;
            createdAt: null;
            updatedAt: null;
        };
    };
    share_app_uri: string;
    show_do_not_call_check_mark: boolean;
    need_to_reset_password: boolean;
    mercure_jwt: string;
    mercure_topic: string;
};

const userCancelToken = Axios.CancelToken.source();

const errorHandler = (error: any, defaultMessage: string) => {
    if (Axios.isCancel(error)) {
        return;
    }
    const errorMessage = error?.message || defaultMessage;
    message.error(errorMessage);
};

export const UserActions: UserType = {
    fetchUser() {
        return async (dispatch, getState, { api, defaultFetch }) => {
            dispatch(this.setNetworkStatus(NetworkStatus.loading));
            return defaultFetch
                .post(
                    api.updateUserDataByJWTToken,
                    {},
                    {
                        headers: {
                            ordersource: 'site',
                        },
                        cancelToken: userCancelToken.token,
                    },
                )
                .then(() => {
                    return batch(() => {
                        dispatch(this.fetchProfile());
                        dispatch(this.setNetworkStatus(NetworkStatus.ready));
                        dispatch(AddressesActions.fetchAddresses());
                        const { info } = getState().cart;
                        if (info) {
                            dispatch(CartActions.authenticate());
                        }
                    });
                })
                .catch(e => {
                    dispatch(this.setNetworkStatus(NetworkStatus.ready));
                    errorHandler(e, 'Failed to fetch user');
                });
        };
    },
    fetchProfile() {
        return async (dispatch, _, { api, defaultFetch }) => {
            defaultFetch
                .get<TUserData>(api.getProfile)
                .then(({ data }) => {
                    dispatch(this.setUser(data));
                })
                .catch(e => errorHandler(e, 'Failed to fetch profile'));
        };
    },
    assignUser(phone, fio) {
        return async (_, getState, { defaultFetch, services }) => {
            const { info } = getState().cart;
            const cart = info?.cart;
            if (cart) {
                const data = {
                    cart,
                    phoneNumber: phone,
                    name: fio,
                };
                const url = `${services.cart.domain}${services.cart.assignUser}`;
                defaultFetch.post(url, data).catch(e => errorHandler(e, 'Failed to assign user'));
            }
        };
    },
    register(values) {
        return async (dispatch, _, { defaultFetch, services }) => {
            const url = `${services.auth.domain}${services.auth.completeRegistration}`;
            await defaultFetch
                .post(url, values)
                .then(() => {
                    return refreshToken().then(() => {
                        return dispatch(this.fetchUser());
                    });
                })
                .catch(e => errorHandler(e, 'Failed to register user'));
        };
    },
    logoutUser() {
        return async dispatch => {
            batch(() => {
                dispatch(this.setUser(null));
                dispatch(CartActions.clearCart());
            });
        };
    },
    setUser(data) {
        return {
            type: Types.SET_USER,
            payload: data,
        };
    },
    setNetworkStatus(status) {
        return {
            type: Types.SET_NETWORK_STATUS,
            payload: status,
        };
    },
};
