import Axios from 'axios';
import { batch } from 'react-redux';

import { TCategory, TProduct } from '../../routes/catalog/hooks/useCatalog';
import { DateHelper } from '../../utils/DateHelper';
import NetworkStatus from '../../utils/enums/NetworkStatus';
import { TAction } from '../store';

export const Types = {
    SET_PRODUCTS: 'CATALOG@SET:PRODUCTS',
    SET_CATEGORIES: 'CATALOG@SET:CATEGORIES',
    SET_CATALOG: 'CATALOG@SET:CATALOG',
    SET_NETWORK_STATUS: 'CATALOG@SET:NETWORK:STATUS',
    SET_ACTIVE_TAB: 'CATALOG@SET:ACTIVE:TAB',
    SET_IS_TOUCH_TAB: 'CATALOG@SET:IS:TOUCH:TAB',
};

export type TCatalogProduct = {
    id: number;
    sequence: number;
    name: string;
    description: string;
    price: number;
    premium_price: number;
    volume: string;
    volume_ml: number | null;
    count: number;
    is_visible: number;
    created_at: string;
    updated_at: string;
    category_id: number;
    image: string;
    image_thumbnail: string;
    images: {
        id: number;
        image: string;
        image_thumbnail: string;
    }[];
    related_products: {
        id: number;
    }[];
    type: string;
    cash_back_percent: string;
    cash_back_sum: number;
    min_quantity: number;
    max_quantity: number | null;
    stock: number | null;
    product_set_id: number | null;
};

export type TCatalog = {
    id: number;
    sequence: number;
    name: string;
    description: string | null;
    is_visible: number;
    is_promotion: number;
    updated_at: string;
    created_at: string;
    parent_id: string | null;
    products: TCatalogProduct[];
};

export type TProductsAction = {
    type: typeof Types.SET_PRODUCTS;
    payload: Record<number, { totalCount: number; products: Record<number, TProduct[]> }>;
};

export type TSetLoadedAction = {
    type: typeof Types.SET_NETWORK_STATUS;
    payload: NetworkStatus;
};

type TCategoriesAction = {
    type: typeof Types.SET_CATEGORIES;
    payload: TCategory[];
};

type TSetActiveTabAction = {
    type: typeof Types.SET_CATEGORIES;
    payload: number;
};

type TSetIsTouchTab = {
    type: typeof Types.SET_IS_TOUCH_TAB;
    payload: boolean;
};

type TCatalogAction = {
    type: typeof Types.SET_CATALOG;
    payload: TCatalog[];
};

export type CatalogActions = TProductsAction | TCategoriesAction | TCatalogAction;

type CatalogsActionsType = {
    fetchFirstPage: () => TAction<Promise<void>>;
    fetchCategories: () => TAction<Promise<void>>;
    fetchProductsByCategoryId: (categoryId: number, page?: number) => TAction<Promise<void>>;
    fetchAllProducts: () => TAction<Promise<void>>;
    setCategories: (categories: TCategory[]) => TCategoriesAction;
    setCatalog: (catalog: TCatalog[]) => TCatalogAction;
    getProduct: (catalog: number) => TAction<TCatalogProduct | undefined>;
    setProducts: (
        products: Record<number, { totalCount: number; products: Record<number, TProduct[]> }>,
    ) => TProductsAction;
    setNetworkStatus: (status: NetworkStatus) => TSetLoadedAction;
    setActiveTab: (value: number) => TSetActiveTabAction;
    setIsTouchTab: (value: boolean) => TSetIsTouchTab;
};

type Pagination = {
    total_count: number;
    count_per_page: number;
    current_page_number: number;
    has_next_page: boolean;
};

type TAxiosResponse<T> = {
    success: boolean;
    message: string | null;
    data: T;
    pagination?: Pagination;
};

const catalogCancelToken = Axios.CancelToken.source();

export const CatalogsActions: CatalogsActionsType = {
    fetchFirstPage() {
        return async (dispatch, getState, { api, defaultFetch }) => {
            const { categories } = getState().catalog;
            const cityId = 6;
            const paymentType = 1;
            const items: Record<
                number,
                { totalCount: number; products: Record<number, TProduct[]> }
            > = {};
            await Promise.all(
                categories.map(category => {
                    return defaultFetch
                        .get<TAxiosResponse<TProduct[]>>(api.productsWithPagination, {
                            params: {
                                date: DateHelper.formatDate(new Date()),
                                cityId,
                                categoryId: category.id,
                                paymentType,
                                page: 1,
                            },
                        })
                        .then(({ data }) => {
                            if (data.success) {
                                items[category.id] = {
                                    totalCount: data.pagination?.total_count || 0,
                                    products: {
                                        1: data.data,
                                    },
                                };
                            }
                        });
                }),
            );
            dispatch(this.setProducts(items));
        };
    },
    fetchCategories() {
        return async (dispatch, _, { api, defaultFetch }) => {
            const cityId = 6;
            defaultFetch
                .get<TAxiosResponse<TCategory[]>>(api.categories, {
                    params: {
                        cityId,
                    },
                })
                .then(({ data }) => {
                    if (data.success) {
                        dispatch(this.setCategories(data.data));
                    }
                });
        };
    },
    fetchProductsByCategoryId(categoryId, page = 1) {
        return async (dispatch, getState, { api, defaultFetch }) => {
            const { products } = getState().catalog;
            const items = products[categoryId].products;
            if (!products[categoryId].products[page]) {
                const cityId = 6;
                const paymentType = 1;
                return defaultFetch
                    .get<TAxiosResponse<TProduct[]>>(api.productsWithPagination, {
                        params: {
                            date: DateHelper.formatDate(new Date()),
                            cityId,
                            categoryId,
                            paymentType,
                            page,
                        },
                    })
                    .then(({ data }) => {
                        items[page] = data.data;
                        products[categoryId].products = items;
                        dispatch(this.setProducts({ ...products }));
                    });
            }
        };
    },
    getProduct(id) {
        return (_, getState) => {
            const { catalog } = getState();

            let product: TCatalogProduct | undefined = undefined;

            catalog.catalog.forEach(category => {
                category.products.forEach((item: TCatalogProduct) => {
                    if (item.id === id) {
                        product = item;
                    }
                });
            });

            return product;
        };
    },
    fetchAllProducts() {
        const cityId = 6;
        const paymentType = 1;
        return async (dispatch, _, { api, defaultFetch }) => {
            dispatch(this.setNetworkStatus(NetworkStatus.loading));
            return defaultFetch
                .get(api.products, {
                    params: {
                        date: DateHelper.formatDate(new Date()),
                        cityId,
                        paymentType,
                    },
                    cancelToken: catalogCancelToken.token,
                })
                .then(({ data }) => {
                    if (data.success) {
                        batch(() => {
                            dispatch(this.setNetworkStatus(NetworkStatus.ready));
                            dispatch(this.setCatalog(data.data));
                        });
                    }
                })
                .catch(() => {
                    // TODO: вывести ошибку
                    dispatch(this.setNetworkStatus(NetworkStatus.ready));
                });
        };
    },
    setProducts(products) {
        return {
            type: Types.SET_PRODUCTS,
            payload: products,
        };
    },
    setCategories(categories) {
        return {
            type: Types.SET_CATEGORIES,
            payload: categories,
        };
    },
    setCatalog(catalog) {
        return {
            type: Types.SET_CATALOG,
            payload: catalog,
        };
    },
    setNetworkStatus(status) {
        return {
            type: Types.SET_NETWORK_STATUS,
            payload: status,
        };
    },
    setActiveTab(value) {
        return {
            type: Types.SET_ACTIVE_TAB,
            payload: value,
        };
    },
    setIsTouchTab(value) {
        return {
            type: Types.SET_IS_TOUCH_TAB,
            payload: value,
        };
    },
};
