import { TReturnedContainers, Types } from '../actions/returnedContainers';

export declare type ReturnedContainers = {
    quantity: number;
    maxQuantity: number;
};

const initState: ReturnedContainers = {
    quantity: 0,
    maxQuantity: 0,
};

const returnedContainers = (state = initState, action: TReturnedContainers): ReturnedContainers => {
    let newState;
    switch (action.type) {
        case Types.SET_CONTAINER_QUANTITY:
            newState = { ...state, quantity: action.payload };
            break;
        case Types.SET_CONTAINER_MAX_QUANTITY:
            newState = { ...state, maxQuantity: action.payload };
            break;
    }
    return (newState as any) || state;
};

export default returnedContainers;
