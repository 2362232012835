import { combineReducers } from 'redux';

import addresses from './addresses';
import cart from './cart';
import catalog from './catalog';
import company from './company';
import history from './history';
import layout from './layout';
import order from './order';
import returnedContainers from './returnedContainers';
import user from './user';

const rootReducer = combineReducers({
    catalog,
    cart,
    returnedContainers,
    company,
    user,
    addresses,
    order,
    history,
    layout,
});

export default rootReducer;

export type AppStateType = ReturnType<typeof rootReducer>;
