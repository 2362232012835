import NetworkStatus from '../../utils/enums/NetworkStatus';
import { TCartActions, TCartCalculate, TCartItem, TInitCart, Types } from '../actions/cart';

export declare type CatalogState = {
    info: TInitCart | null;
    items: Record<number, TCartItem> | null;
    calculate: TCartCalculate | null;
    calculateNetworkStatus: NetworkStatus;
    detailNetworkStatus: NetworkStatus;
};

const initState: CatalogState = {
    info: null,
    items: null,
    calculate: null,
    calculateNetworkStatus: NetworkStatus.pending,
    detailNetworkStatus: NetworkStatus.pending,
};

const Cart = (state = initState, action: TCartActions): CatalogState => {
    let newState;
    switch (action.type) {
        case Types.INIT_CART:
            newState = { ...state, info: action.payload };
            break;
        case Types.SET_PRODUCTS:
            newState = { ...state, items: action.payload };
            break;
        case Types.SET_CALCULATE_NETWORK_STATUS:
            newState = { ...state, calculateNetworkStatus: action.payload };
            break;
        case Types.SET_DETAIL_NETWORK_STATUS:
            newState = { ...state, detailNetworkStatus: action.payload };
            break;
        case Types.SET_CALCULATE:
            newState = { ...state, calculate: action.payload };
            break;
    }
    return (newState as any) || state;
};

export default Cart;
