import React from 'react';

import { lazy } from '@loadable/component';
import { ConfigProvider as AntdConfigProvider } from 'antd';
import { createBrowserHistory } from 'history';
import { Provider as ReduxProvider } from 'react-redux';
import { Router, Switch, Route } from 'react-router-dom';
import { useUrlSearchParams } from 'use-url-search-params';
import 'url-search-params-polyfill';

import AuthService from './aqua-delivery-web-client-ui/AuthService';
import EmptyPanel from './aqua-delivery-web-client-ui/components/EmptyPanel';
import Loader from './aqua-delivery-web-client-ui/components/loader/Loader';
import LoaderContainer from './aqua-delivery-web-client-ui/components/loader/LoaderContainer';
import SuspenseWithLoader from './aqua-delivery-web-client-ui/components/loader/SuspenseWithLoader';
import useDocumentLanguage from './aqua-delivery-web-client-ui/hooks/useDocumentLanguage';
import I18n, { I18nSetConfig, I18nContext } from './aqua-delivery-web-client-ui/i18n';
import Localization from './aqua-delivery-web-client-ui/localization/Localization';
import localizationConfig from './aqua-delivery-web-client-ui/localization/localizationConfig';
import YandexAnalytics from './aqua-delivery-web-client-ui/YandexAnalytics';
import { config } from './config';
import useAntdLocale from './hooks/useAntdLocale';
import translations from './translations';

import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/free-mode/free-mode.less';
import 'swiper/modules/navigation/navigation.less';

import './aqua-delivery-web-client-ui/assets/styles/common.less';

const history = createBrowserHistory();

const ClientAsync = lazy(() => import('./routes/'));

const TechnicalWorkPageAsync = lazy(
    () => import('./aqua-delivery-web-client-ui/components/static-pages/TechnicalWorkPage'),
);

const Page404Async = lazy(
    () => import('./aqua-delivery-web-client-ui/components/static-pages/Page404'),
);

I18nSetConfig({ translations });

const ymOptions = { clickmap: true, webvisor: true };

const ymInitializerOptions = { version: '2' };

const App: React.FC = () => {
    React.useEffect(() => {
        window.document.title = 'Aqua Delivery';
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [locale, setLocale] = React.useState(I18n.locale);

    const { antdLocale } = useAntdLocale();

    const [appParams] = useUrlSearchParams({}, { isAppUpdating: Boolean });

    const isAppUpdating = config.isAppUpdating && appParams.isAppUpdating !== false;

    const { updateDocumentLocale } = useDocumentLanguage();

    React.useEffect(() => {
        updateDocumentLocale(locale);
    }, [locale, updateDocumentLocale]);

    const authToken = AuthService.getToken();

    const userVisitParams = React.useMemo(() => {
        if (!authToken) return undefined;

        const userInfo = AuthService.decodeToken(authToken);

        return {
            UserID: userInfo.uuid,
        };
    }, [authToken]);

    return (
        // Use require instead of import to initialize redux store before fetching cloud config.
        // Import executes modules asynchronously even before rendering App.tsx, while require executes
        // redux store strictly when App.tsx is being rendering.
        <ReduxProvider store={require('./redux/store').default}>
            <I18nContext.Provider
                value={{
                    locale,
                    setLocale: locale => {
                        I18nSetConfig({ locale });
                        setLocale(locale);
                    },
                }}
            >
                <YandexAnalytics
                    accounts={config.metrics.ym}
                    ymOptions={ymOptions}
                    visitParams={userVisitParams}
                    ymInitializerOptions={ymInitializerOptions}
                >
                    <LoaderContainer height={window.innerHeight}>
                        <SuspenseWithLoader fallback={<Loader loading={true} />}>
                            {isAppUpdating ? (
                                <TechnicalWorkPageAsync />
                            ) : (
                                <AntdConfigProvider
                                    locale={antdLocale}
                                    renderEmpty={() => <EmptyPanel />}
                                >
                                    <Localization.Provider value={localizationConfig}>
                                        <Router history={history}>
                                            <Switch>
                                                <Route
                                                    history={history}
                                                    path="/"
                                                    component={ClientAsync}
                                                />
                                                <Route component={Page404Async} />
                                            </Switch>
                                        </Router>
                                    </Localization.Provider>
                                </AntdConfigProvider>
                            )}
                        </SuspenseWithLoader>
                    </LoaderContainer>
                </YandexAnalytics>
            </I18nContext.Provider>
        </ReduxProvider>
    );
};

export default App;

export const AppLoader: React.FC = () => (
    <LoaderContainer height={window.innerHeight}>
        <Loader loading={true} />
    </LoaderContainer>
);
